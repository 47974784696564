import React from "react"
import { graphql } from "gatsby"
import App from "../components/app"
import RecommendedPosts from "../components/recommendedPosts"

export default function HelpCenterPage({ data }) {
  const posts = data.allMdx.edges

  return (
    <App title="Help Center">
      <div className="container mx-auto py-12">
        <div className="mt-12">
          <p className="text-blue-600 text-xl">Help Center</p>
          <h1 className="mt-8 text-3xl tracking-tight font-semibold leading-10 text-gray-900 sm:text-4xl sm:leading-none md:text-5xl">
            Hi, How may GoGo help you?
          </h1>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-10">
          <div className="rounded-lg shadow-md p-6 border">
            <div className="md:flex items-center">
              <div className="md:flex-shrink-0">
                <img
                  className="rounded-lg md:w-56"
                  src="https://gogo-core-staging-hosts.s3.amazonaws.com/1601102547-5f6ee2d3279fb/1601102547-5f6ee2d3279fb"
                  alt="Woman paying for a purchase"
                />
              </div>
              <div className="mt-4 md:mt-0 md:ml-6 whitespace-pre-wrap">
                <div
                  href="#"
                  className="block mt-1 leading-tight text-gray-900 text-3xl font-medium"
                >
                  Booking and Travel
                </div>
                <p className="mt-4 text-gray-800 text-xl">
                  GoGo at your service with booking and travel accommodation
                </p>
              </div>
            </div>
          </div>

          <div className="rounded-lg shadow-md p-6 border">
            <div className="md:flex items-center">
              <div className="md:flex-shrink-0">
                <img
                  className="rounded-lg md:w-56"
                  src="https://gogo-core-staging-hosts.s3.amazonaws.com/1601102579-5f6ee2f3b43a5/1601102579-5f6ee2f3b43a5"
                  alt="Woman paying for a purchase"
                />
              </div>
              <div className="mt-4 md:mt-0 md:ml-6 whitespace-pre-wrap">
                <div
                  href="#"
                  className="block mt-1 leading-tight text-gray-900 text-3xl font-medium"
                >
                  Hosting
                </div>
                <p className="mt-4 text-gray-800 text-xl">
                  GoGo at your service to enable your hosting accommodations
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 sm:mt-8 md:mt-10 lg:mt-14 xl:mt-16">
          <h4 className="text-2xl tracking-tight font-semibold leading-10 text-gray-900 sm:text-4xl sm:leading-none md:text-3xl text-center">
            Recommended articles
          </h4>
        </div>

        <div className="mt-4 sm:mt-6 md:mt-8 lg:mt-10 xl:mt-12">
          <RecommendedPosts posts={posts} />
        </div>
      </div>
    </App>
  )
}

export const pageQuery = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 6
      filter: { frontmatter: { recommended: { eq: true } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            color
            date(formatString: "MMMM DD, YYYY")
            title
            description
            recommended
            image {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
