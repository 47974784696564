import React from "react"
import { Link } from "gatsby"

const RecommendedPosts = ({ posts }) => {
  return (
    <div className="relative max-w-lg mx-auto lg:max-w-7xl">
      {posts.map(({ node }, index) => {
        const odd = index % 2 === 0
        const color = node.frontmatter.color
        const title = node.frontmatter.title || node.fields.slug

        return (
          <div
            key={node.fields.slug}
            className={`flex mt-5 ${odd ? "flex-row-reverse" : "flex-row"}`}
          >
            <div className="w-4/12">
              <img
                src={node.frontmatter.image.childImageSharp.fluid.src}
                className="w-full rounded-xl"
              />
            </div>
            <div
              style={{ backgroundColor: color }}
              className={`bg-gray-100 flex flex-col px-8 justify-center rounded-xl w-8/12 ${
                odd ? "mr-5" : "ml-5"
              }`}
            >
              <h3
                className={`font-semibold text-xl ${
                  color ? "text-white" : "text-gray-900"
                }`}
              >
                {title}
              </h3>
              <div
                className={`mt-5 ${color ? "text-gray-200" : "text-gray-500"}`}
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
              <div className="mt-5">
                <Link
                  to={node.fields.slug}
                  className={`inline-block px-3 py-2 rounded-sm font-semibold transition ease-in-out duration-150 ${
                    color
                      ? "bg-white"
                      : "bg-blue-100 text-indigo-600 hover:text-indigo-500"
                  }`}
                >
                  Know More
                </Link>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default RecommendedPosts
